import React from "react";
import { Carousel, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";


function MainBanners({ banners }) {
  return (
    <div className="container-fluid position-relative p-0 set_max_width_banner">
      <Carousel
        variant="dark"
        interval={10000}
        nextIcon={<span className="carousel-control-next-icon" aria-hidden="true"></span>}
        prevIcon={<span className="carousel-control-prev-icon" aria-hidden="true"></span>}
      >
        <div className="overlay"></div>
        {banners.map((banner, index) => (
          <Carousel.Item key={index}>
            <div className="my-auto">
              <img
                src={banner.url}
                className="d-block w-100 object-fit-cover"
                alt={`Slide ${index + 1}`}
                height={650}

              />
              <Carousel.Caption className="d-md-block" style={{ marginBottom: "190px", }}>
                <h6 className="fs-1">{banner.heading}</h6>
                <p className="fs-4 mb-5">{banner.paragraph}</p>
                <div className="mt-5 position-absolute left-50">
                  <a href="/Book_Services"><Button variant="primary" className="read_more">Book a Service</Button></a>
                  <a href="/Contact-us"><Button variant="primary" className="get_a_quote">Get a Quote</Button></a>
                </div>

              </Carousel.Caption>
            </div>
          </Carousel.Item>

        ))}
      </Carousel>
    </div>
  );
}

MainBanners.defaultProps = {
  banners: [],
};

export default MainBanners;
