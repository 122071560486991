import { useContext, useState } from "react";
import React from "react";
import { StepContext } from "../../../Context/StepContext";
import { Accordion, Collapse, useAccordionButton } from "react-bootstrap";
import img from "../../../assets/images/section_5.jpg";
import { TermsCondition } from "../../../Components/TermsCondition";
import { addVehicleDetails } from "../../../firebase";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
const StepFive = () => {
    const { setActiveStep, formData, updateFormData } = useContext(StepContext);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const toggleOpen = () => setIsOpen(!isOpen);

    const handleSubmitData = async (e) => {

        e.preventDefault();
        const data = {
            stepOneData: formData.stepOneData,
            stepTwoData: formData.stepTwoData,
            stepThreeData: formData.stepThreeData,
            stepFourData: formData.stepFourData,
            stepFiveData: formData.stepFiveData,
        };

        try {
            setLoading(true);
            await addVehicleDetails(data);
            console.log("Data submitted successfully");
            toast.success("Data submitted successfully");
            navigate("/");
        } catch (error) {
            console.error("Error submitting data:", error);
            toast.error("Sorry your request failed:");
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div className="row fifth_portion">
                <div className="col-lg-12 mt-4 mx-auto fifth_portion overflow-x-hidden">
                    <p>
                        Please check the following details are correct and accept the terms
                        &amp; conditions. Incorrect information may invalidate your policy
                        at the point of a claim.
                    </p>
                    <div className="container ">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="table-container bg-light">
                                    <h4>My Vehicle Details</h4>
                                    <table className="vehicle-table">
                                        <tbody>
                                            <tr>
                                                <th>Reg</th>
                                                <td>{formData.stepOneData.carRegistration}</td>
                                            </tr>
                                            <tr>
                                                <th>Make</th>
                                                <td>{formData.stepOneData.selectedCar}</td>
                                            </tr>
                                            <tr>
                                                <th>Model</th>
                                                <td>{formData.stepOneData.selectedMaker}</td>
                                            </tr>
                                            <tr>
                                                <th>MOT expiry date</th>
                                                <td>{formData.stepOneData.motExpiryDate}</td>
                                            </tr>
                                            <tr>
                                                <th>Current Mileage</th>
                                                <td>{formData.stepOneData.estimatedMileage}</td>
                                            </tr>
                                            <tr>
                                                <th>Last service date</th>
                                                <td>{formData.stepOneData.firstRegistrationDate}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button className="btn btn-secondary btn-custom " onClick={() => setActiveStep(0)}>
                                        Edit-mymotor
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="table-container bg-light">
                                    <h4>My Personal Details</h4>
                                    <table className="personal-table vehicle-table ">
                                        <tbody>
                                            {/* <tr>
                  <th>Title</th>
                  <td>{formData.stepTwoData.title}</td>
                </tr> */}
                                            <tr>
                                                <th>First name</th>
                                                <td>{formData.stepTwoData.fname}</td>
                                            </tr>
                                            <tr>
                                                <th>Surname</th>
                                                <td>{formData.stepTwoData.lastname}</td>
                                            </tr>
                                            <tr>
                                                <th>Phone number</th>
                                                <td>{formData.stepTwoData.phone}</td>
                                            </tr>
                                            <tr>
                                                <th>Email</th>
                                                <td>{formData.stepTwoData.email}</td>
                                            </tr>
                                            {/* <tr>
                  <th >OwnerShip</th>
                  <td>{formData.stepTwoData.ownership}</td>
                </tr> */}
                                            <tr>
                                                <th>Buy Date</th>
                                                <td>{formData.stepTwoData.buydate}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button className="btn btn-secondary btn-custom-personal" onClick={() => setActiveStep(2)}>
                                        Edit-myinfo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container bg-light justify-content-between flex-row p-3 mt-4 mb-4">
                        <div className="detail-section d-flex justify-content-between">
                            <h4>My warranty details</h4>
                            <button
                                type="button"
                                className="btn btn-secondary mt-auto align-self-end  "
                                onClick={toggleOpen}
                            >
                                Expand and check
                            </button>
                        </div>
                        <Collapse in={isOpen}>
                            <div className="mt-3">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <h6>Car Warranty Name: Plan A</h6>
                                        <h6>What Is Included?</h6>
                                        <ul className="p-0 text-justify">
                                            <li>Air conditioning</li>
                                            <li>Braking System hydraulics</li>
                                            <li>
                                                Braking System cables, pedal assembly &amp; vacuum pump
                                            </li>
                                            <li>Clutch plate &amp; cover &amp; release bearing</li>
                                            <li>Cooling system thermostat &amp; water pump</li>
                                            <li>Cooling system radiators &amp; fans</li>
                                            <li>Main ECU (computer)</li>
                                            <li>
                                                Ignition system - coils and crankshaft &amp; camshaft
                                                sensors
                                            </li>
                                            <li>Airbag electrics (external to airbags)</li>
                                            <li>Gearboxes</li>
                                            <li>Starter motor &amp; alternator</li>
                                            <li>Main electrical system components</li>
                                            <li>Emission system components</li>
                                            <li>Other ECUs (computers)</li>
                                            <li>Clutch ancillaries</li>
                                            <li>Engine</li>
                                            <li>Fluids &amp; consumables</li>
                                            <li>Flywheels &amp; drive plates</li>
                                            <li>Fuel system - pumps &amp; tank</li>
                                            <li>Fuel system - other components</li>
                                            <li>Ignition system - other components</li>
                                            <li>Crankshaft rear oil seal</li>
                                            <li>Casings</li>
                                            <li>Gearbox input shaft oil seal</li>
                                            <li>Other oil seals &amp; gaskets</li>
                                            <li>
                                                Catalytic converters &amp; diesel particulate filters
                                            </li>
                                            <li>Power steering motor, pump and ram</li>
                                            <li>Other steering system components</li>
                                            <li>Super/turbochargers</li>
                                            <li>Suspension</li>
                                            <li>Transmissions</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-5">
                                        <h6>Warranty Payment Preference</h6>
                                        <div className="warranty-item">
                                            <strong>Continuous Plan:</strong>
                                            <span className="ms-2">Yes</span>
                                        </div>
                                        <div className="warranty-item">
                                            <strong>Repairer Preference:</strong>
                                            <span className="ms-2">MotorEasy Network</span>
                                        </div>
                                        <div className="warranty-item">
                                            <strong>Garage Labour Rate:</strong>
                                            <span className="ms-2">£60</span>
                                        </div>
                                        <div className="warranty-item">
                                            <strong>Your Repair Contribution:</strong>
                                            <span className="ms-2">£50</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="warranty-item">
                                            <strong>Total Price</strong>
                                            <span className="ms-2">£50.45</span>
                                        </div>
                                    </div>
                                    <div className="text-start mb-5 car_warrarnty">
                                        <button className="p-2 ">Edit - My car warranty</button>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                    <>
                        {/* vocher */}
                        <div className="container mt-3 mb-5">
                            <div className="vocher bg-light p-4">
                                <div className="detail-section">
                                    <h4>Vocher</h4>
                                    <p>
                                        <span className="text-primary fw-bold">TVWARRANTY11</span> -
                                        11% OFF a car Warranty! <a href="javascript:void(0)">remove</a>
                                    </p>
                                </div>
                                <div className="input-section">
                                    <label htmlFor="des">Your Code</label>
                                    <br />
                                    <div className="d-flex align-content-center mt-2">
                                        <input type="text" />
                                        <button className="bg-secondary text-light fw-600 py-2 px-3 border-none">
                                            Check Voucher Code
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TermsCondition />
                    </>
                    <div className="container mx-auto   mt-3 ">
                        <div className=" row rolling mt-4 p-3 bg-light">
                            <div className="col-lg-6 mx-auto d-flex justify-content-center align-items-start  flex-column">
                                <h4>
                                    <b>{formData.stepThreeData.plan}</b>
                                </h4>
                                <h6>(Rolling monthly)</h6>
                            </div>
                            <div className="col-lg-6 mx-auto d-flex justify-content-start align-items-center">
                                <h6>
                                    <span>
                                        <s style={{ color: "#f5c655" }}>
                                            duration: {formData.stepThreeData.duration || formData.stepThreeData.originalPrice}
                                        </s>
                                    </span>
                                    &nbsp;&nbsp; AUS ${formData.stepThreeData.price} &nbsp;&nbsp;
                                </h6>
                            </div>
                            <hr />
                        </div>
                        <div className="row text-end">
                            <div className="info-section-5 bg-dark p-3 d-flex justify-content-end align-items-center">
                                <h3 className="text-light fw-semibold">Payable today</h3>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <p className="bg-dark">
                                    <s className="text-warning fs-5">
                                        {formData.stepThreeData.duration}
                                    </s>{" "}
                                    <span className="fs-5 fw-bold bg-danger text-light bg-danger rounded-2 p-2 ">
                                        {formData.stepThreeData.price}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="two_step_button d-flex justify-content-between mt-5">
                            <button className="text-start" onClick={() => setActiveStep(4)} >
                                Previous step
                            </button>
                            <button className="text-end" onClick={handleSubmitData}>
                                {loading ? "plz wait..." : "Next step -Secure Payment"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default StepFive;
