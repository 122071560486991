import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CarForm = () => {
    const [carName, setCarName] = useState("");
    const csrfToken =
        document
            .querySelector('meta[name="csrf-token"]')
            ?.getAttribute("content") || "";

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(
                "https://phplaravel-1324103-4877027.cloudwaysapps.com/api/store-cars",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json", // Ensure this header is set correctly
                    },
                    body: JSON.stringify({ car_name: carName }), // Use JSON.stringify for JSON payload
                }
            );

            console.log(response);
            if (response.ok) {
                setCarName("");
                toast.success("Car added successfully!");
            } else {
                const errorData = await response.text(); // Get response text to check for error details
                toast.error(`Error adding car: ${errorData}`);
            }
        } catch (error) {
            toast.error(`Error adding car: ${error.message}`);
        }
    };

    return (
        <Container>
            <ToastContainer />
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3  align-items-end">
                    <Col xs="10" className="p-0">
                        <Form.Group controlId="formCarName">
                            <Form.Label>Car Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={carName}
                                onChange={(e) => setCarName(e.target.value)}
                                placeholder="Enter car name"
                            />
                        </Form.Group>
                    </Col>

                    <Col xs="2">
                        <Button variant="primary" className="w-100" type="submit">
                            Add Car
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default CarForm;
