import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    Dropdown,
    DropdownButton,
    Carousel,
    Spinner,
} from "react-bootstrap";
import TopHeader from "../../Components/TopHeader";
import MainHeader from "../../Components/MainHeader";
import Footer from "../../Components/Footer";
import "../../Pages/ProductsDetails/ProductsDetails.css";
import { FaCartPlus } from "react-icons/fa";
import axios from "axios";

const ProductDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // console.log("Location State:", location.state);

    const { car_id, maker_id, model_id } = location.state || {};
    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    // console.log("selected car is and model id is", car_id, " + ", model_id)
    useEffect(() => {
        const fetchCarData = async () => {
            if (!car_id || !maker_id) {
                setError("Car ID, Maker ID, and Model ID are required.");
                setLoading(false);
                return;
            }

            setLoading(true);
            setError("");
            try {
                // Fetch model details using car_id, maker_id, and model_id
                const modelResponse = await axios.get(
                    "https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-models",
                    {
                        params: { car_id, maker_id, model_id },
                    }
                );

                console.log("Model response data:", modelResponse.data);

                if (
                    modelResponse.data &&
                    Array.isArray(modelResponse.data.models) &&
                    modelResponse.data.models.length > 0
                ) {
                    // Inside the useEffect hook where models are fetched:
                    const modelsWithDetails = await Promise.all(
                        modelResponse.data.models.map(async (model) => {
                            try {
                                const imageResponse = await fetch(
                                    "https://phplaravel-1324103-4877027.cloudwaysapps.com/api/get-car-img",
                                    {
                                        method: "POST",
                                        headers: { "Content-Type": "application/json" },
                                        body: JSON.stringify({
                                            car_id: car_id,
                                            maker_id: maker_id,
                                            model_id: model.id,
                                        }),
                                    }
                                );

                                if (!imageResponse.ok) {
                                    console.error(
                                        `Failed to fetch images for model: ${model.id}`
                                    );
                                    throw new Error("Failed to fetch images");
                                }

                                const imageData = await imageResponse.json();
                                const images = imageData.all_images.map((img) => ({
                                    ...img,
                                    url: `${imageData.imagepath}${img.car_image}`,
                                }));

                                const videos = imageData.all_videos
                                    .filter((vid) => vid.car_video !== null)
                                    .map((vid) => ({
                                        ...vid,
                                        url: `${imageData.videopath}${vid.car_video}`,
                                    }));

                                const priceResponse = await fetch(
                                    `https://phplaravel-1324103-4877027.cloudwaysapps.com/api/get-car-model-price/${model.id}`
                                );

                                if (!priceResponse.ok) {
                                    console.error(
                                        `Failed to fetch prices for model: ${model.id}`
                                    );
                                    throw new Error("Failed to fetch prices");
                                }

                                const priceData = await priceResponse.json();

                                // Set the price to the first available plan or fallback
                                const modelPrice =
                                    priceData.model_prices && priceData.model_prices.length > 0
                                        ? priceData.model_prices[0].price
                                        : "Price not available";

                                return {
                                    ...model,
                                    images,
                                    videos,
                                    plans: priceData.model_prices,
                                    activePlan: null,
                                    price: modelPrice, // Assign price here
                                };
                            } catch (imageError) {
                                console.error(
                                    `Error fetching images for model ${model.id}:`,
                                    imageError
                                );
                                return {
                                    ...model,
                                    images: [],
                                    videos: [],
                                    plans: [],
                                    activePlan: null,
                                    price: "Price not available",
                                };
                            }
                        })
                    );

                    setModels(modelsWithDetails);
                } else {
                    console.warn("No models data available or incorrect format");
                    throw new Error("No models data available");
                }
            } catch (error) {
                console.error("Error fetching car details:", error);
                setError(error.message || "Failed to fetch car details");
                setModels([]);
            } finally {
                setLoading(false);
            }
        };

        fetchCarData();
    }, [car_id, maker_id, model_id]);

    const handleSelectPlan = (modelId, selectedPlanId) => {
        const updatedModels = models.map((model) => {
            if (model.id === modelId) {
                const selectedPlan = model.plans.find(
                    (plan) => plan.id.toString() === selectedPlanId
                );
                return { ...model, activePlan: selectedPlan };
            }
            return model;
        });
        setModels(updatedModels);
    };

    const addToCartAndNavigate = (model) => {
        if (!model.activePlan) {
            const selectedCarData = {
                id: model.id,
                // name: model.activePlan.plan_name,
                images: model.images,
                videos: model.videos, // Ensure videos are included
                plan: null,
                quantity: 1,
                // totalPrice: parseFloat(model.activePlan.price),
                productCode: model.product_code,
                productDescription: model.product_desc,
                headTitle: model.head_title,
                engineCapacity: model.engine_capacity || "N/A",
                modelName: model.model_name,
                carType: model.car_type || "N/A",
                serviceType: model.service_type || "N/A",
            };
            console.log("Car data sent to ProductSingle", selectedCarData);

            navigate("/Product-single", {
                state: { selectedCars: [selectedCarData] },
            });
        }
    };

    return (
        <div>
            <TopHeader />
            <MainHeader />
            <Container className="mt-4 product_details_main">
                {loading ? (
                    <Spinner animation="border" role="status" className="d-block mx-auto">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : models.length > 0 ? (
                    <>
                        <Row className="mb-4">
                            <Col>
                                <h1>Car Details</h1>
                            </Col>
                        </Row>
                        <Row>
                            {models.map((model) => (
                                <Col lg={4} md={6} sm={12} key={model.id} className="mb-4">
                                    <Card className="image-card shadow h-100">
                                        <Carousel
                                            className="image-card-carousel"
                                            interval={null}
                                            style={{ height: "250px" }}
                                        >
                                            {model.images.map((img, idx) => (
                                                <Carousel.Item key={`img-${idx}`}>
                                                    <img
                                                        src={img.url}
                                                        alt={`Model ${model.model_name} ${idx}`}
                                                        className="d-block w-100 h-100 carousel__image"
                                                        style={{
                                                            height: "250px !important",
                                                            objectFit: "cover !important",
                                                        }}
                                                    />
                                                </Carousel.Item>
                                            ))}
                                            {model.videos.map((vid, idx) => (
                                                <Carousel.Item key={`vid-${idx}`}>
                                                    <video
                                                        className="d-block w-100 h-100"
                                                        controls
                                                        src={vid.url}
                                                    >
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </Carousel.Item>
                                            ))}
                                        </Carousel>
                                        <Card.Body className="pb-0">
                                            <Card.Title>
                                                {/* {`${model.make} ${model.model} (${model.year})`} Year, Make, and Model */}
                                                <div className="text-start">
                                                    <h4 className="fw-bold text-primary mb-1 me-1 d-inline">
                                                        {model.head_title || "Details not available"}
                                                    </h4>
                                                    <h5 className="fw-light text-secondary d-inline">
                                                        {model.model_name ? `(${model.model_name})` : "(Name is not available)"}
                                                    </h5>
                                                </div>
                                                <div className="mt-2">
                                                    <p className="fw-light fs-6">
                                                        {model.product_desc || "N/A"}
                                                    </p>
                                                </div>
                                            </Card.Title>
                                            <hr className="bg-secondary" />
                                            <Card.Text className="px-0">
                                                <div className=" text-start">
                                                    <ul className="list-unstyled p-0">
                                                        <li>
                                                            <strong>Car Type:</strong> {model.car_type || "N/A"}
                                                        </li>
                                                        <li>
                                                            <strong>Engine Capacity:</strong> {model.engine_capacity || "N/A"}
                                                        </li>
                                                        <li>
                                                            <strong>Service Type:</strong> {model.service_type || "N/A"}
                                                        </li>
                                                        <li>
                                                            <strong>Product Code:</strong> {model.product_code || "N/A"}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Card.Text>


                                            <hr className="bg-secondary" />
                                        </Card.Body>
                                        <Card.Footer className="d-flex justify-content-between align-items-center px-3 border-0">
                                            <button
                                                className="btn btn-info fw-bold"
                                                onClick={() => addToCartAndNavigate(model)}
                                            >
                                                <FaCartPlus className="me-1" />
                                                View Details
                                            </button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </>
                ) : (
                    <Alert variant="danger">{error || "No car details available."}</Alert>
                )}
            </Container>
            <Footer />
        </div >
    );
};

export default ProductDetails;
