import React, { useState, useEffect } from "react";
import {
    ref,
    listAll,
    getDownloadURL,
    uploadBytesResumable,
    deleteObject,
} from "firebase/storage";
import { storage, db } from "../../../firebase";
import {
    Form,
    Button,
    ProgressBar,
    Container,
    Row,
    Col,
    Table,
    Modal,
} from "react-bootstrap";
import {
    collection,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query,
    where,
    getDocs,
} from "firebase/firestore";
import AdminHeader from "../../../Components/AdminHeader";
import Sidebar from "../../../Components/Sidebar";
import { toast } from "react-toastify";

const AdminBanners = () => {
    const services = [
        "Used Car warranty",
        "Vehicle inspection",
        "Vehicle Service",
        "Road Side",
        "Battery Replacement",
        "Windscreen Repair",
        "Home",
    ];

    const [selectedService, setSelectedService] = useState("Used Car warranty");
    const [images, setImages] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [imageList, setImageList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [heading, setHeading] = useState("");
    const [paragraph, setParagraph] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [newImage, setNewImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState("");

    useEffect(() => {
        fetchImages();
    }, [selectedService]);

    const fetchImages = async () => {
        setLoading(true);
        setError("");
        try {
            const allImages = [];
            for (const service of services) {
                const folderRef = ref(storage, `${service}/`);
                const result = await listAll(folderRef);
                const urls = await Promise.all(
                    result.items.map(async (itemRef) => {
                        const url = await getDownloadURL(itemRef);
                        const q = query(
                            collection(db, "images"),
                            where("name", "==", itemRef.name),
                            where("service", "==", service)
                        );
                        const querySnapshot = await getDocs(q);
                        if (!querySnapshot.empty) {
                            let docData = querySnapshot.docs[0].data();
                            return {
                                id: querySnapshot.docs[0].id,
                                name: itemRef.name,
                                url,
                                service,
                                heading: docData.heading || "",
                                paragraph: docData.paragraph || "",
                            };
                        } else {
                            // Create a new document for the image
                            const docRef = await addDoc(collection(db, "images"), {
                                name: itemRef.name,
                                service: service,
                                url: url,
                                heading: "",
                                paragraph: "",
                            });
                            return {
                                id: docRef.id,
                                name: itemRef.name,
                                url,
                                service,
                                heading: "",
                                paragraph: "",
                            };
                        }
                    })
                );
                allImages.push(...urls);
            }
            setImageList(allImages);
        } catch (error) {
            console.error("Error fetching images:", error);
            setError("Failed to fetch images");
        } finally {
            setLoading(false);
        }
    };

    const handleServiceChange = (e) => {
        setSelectedService(e.target.value);
    };

    const handleImageChange = (e) => {
        if (e.target.files) {
            setImages(Array.from(e.target.files));
        }
    };

    const handleUpload = () => {
        if (!images.length) return;

        images.forEach((image) => {
            const storageRef = ref(storage, `${selectedService}/${image.name}`);
            const uploadTask = uploadBytesResumable(storageRef, image);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress((prev) => ({
                        ...prev,
                        [image.name]: progress,
                    }));
                },
                (error) => {
                    console.error("Upload error:", error);
                    setError("Failed to upload image");
                },
                async () => {
                    try {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        await addDoc(collection(db, "images"), {
                            url: downloadURL,
                            name: image.name,
                            service: selectedService,
                            heading: heading,
                            paragraph: paragraph,
                        });
                        fetchImages(); // Refresh the image list
                    } catch (error) {
                        console.error("Error adding document: ", error);
                        setError("Failed to save image metadata");
                    }
                }
            );
        });
    };

    const handleDelete = async (name, service) => {
        const storageRef = ref(storage, `${service}/${name}`);
        try {
            await deleteObject(storageRef);
            const q = query(
                collection(db, "images"),
                where("service", "==", service),
                where("name", "==", name)
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });
            fetchImages(); // Refresh the image list
        } catch (error) {
            console.error("Error deleting image:", error);
            setError("Failed to delete image");
        }
    };

    const handleDownload = (url, name) => {
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
    };

    const handleEdit = (image) => {
        setSelectedImage(image);
        setHeading(image.heading);
        setParagraph(image.paragraph);
        setPreviewUrl(image.url);
        setShowModal(true);
    };

    const handleImagePreviewChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewImage(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleSaveChanges = async () => {
        try {
            if (selectedImage) {
                const docRef = doc(db, "images", selectedImage.id);

                if (newImage) {
                    const storageRef = ref(storage, `${selectedImage.service}/${newImage.name}`);
                    const uploadTask = uploadBytesResumable(storageRef, newImage);

                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            const progress =
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log(`Upload is ${progress}% done`);
                        },
                        (error) => {
                            console.error("Upload error:", error);
                            setError("Failed to upload image");
                        },
                        async () => {
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            await updateDoc(docRef, {
                                heading: heading,
                                paragraph: paragraph,
                                url: downloadURL,
                                name: newImage.name,
                            });
                        }
                    );
                } else {
                    await updateDoc(docRef, {
                        heading: heading,
                        paragraph: paragraph,
                    });
                }

                setShowModal(false);
                fetchImages(); // Refresh the image list
                toast.success("Data updated successfully");
            }
        } catch (error) {
            console.error("Error updating document: ", error);
            setError("Failed to update image metadata");
        }
    };

    return (
        <>
            <AdminHeader />
            <div className="container-fluid p-0">
                <div className="row">
                    <Sidebar />
                    <div className="col-md-9">
                        <Container>
                            <h1 className="my-4">Manage Banners</h1>
                            <p>
                                Below you can upload banners for different services. Uploading
                                images is optional. If you don’t want to upload new images, you
                                can manage the existing ones instead.
                            </p>
                            <Form>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        Select Service
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control
                                            as="select"
                                            value={selectedService}
                                            onChange={handleServiceChange}
                                        >
                                            {services.map((service) => (
                                                <option key={service} value={service}>
                                                    {service}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        Upload Images
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control
                                            type="file"
                                            multiple
                                            onChange={handleImageChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        Heading
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter heading"
                                            value={heading}
                                            onChange={(e) => setHeading(e.target.value)}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        Paragraph
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Enter paragraph"
                                            value={paragraph}
                                            onChange={(e) => setParagraph(e.target.value)}
                                        />
                                    </Col>
                                </Form.Group>
                                <Button variant="primary" onClick={handleUpload}>
                                    Upload
                                </Button>
                            </Form>
                            {images.length > 0 && (
                                <div className="mt-4">
                                    <h4>Upload Progress</h4>
                                    {images.map((image) => (
                                        <div key={image.name} className="my-2">
                                            <span>{image.name}</span>
                                            <ProgressBar
                                                now={uploadProgress[image.name] || 0}
                                                label={`${Math.round(
                                                    uploadProgress[image.name] || 0
                                                )}%`}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            <h2 className="my-4">Uploaded Images</h2>
                            {loading ? <p>Loading...</p> : null}
                            {error ? <p>{error}</p> : null}
                            {!loading && !error && (
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Image</th>
                                            <th>Service</th>
                                            <th>Heading</th>
                                            <th>Paragraph</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {imageList.map((image, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <img src={image.url} alt={image.name} width="100" />
                                                    <div>{image.name}</div>
                                                </td>
                                                <td>{image.service}</td>
                                                <td>{image.heading}</td>
                                                <td>{image.paragraph}</td>
                                                <td>
                                                    <Button
                                                        variant="warning"
                                                        className="me-2"
                                                        onClick={() => handleEdit(image)}
                                                        disabled={!image.id} // Disable if no Firestore document
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        variant="danger"
                                                        className="me-2"
                                                        onClick={() =>
                                                            handleDelete(image.name, image.service)
                                                        }
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Button>
                                                    <Button
                                                        variant="primary"
                                                        onClick={() =>
                                                            handleDownload(image.url, image.name)
                                                        }
                                                    >
                                                        <i className="fa fa-download"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            )}
                        </Container>
                    </div>
                </div>
            </div>

            {/* Modal for editing image details */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Image Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Heading
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter heading"
                                    value={heading}
                                    onChange={(e) => setHeading(e.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Paragraph
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Enter paragraph"
                                    value={paragraph}
                                    onChange={(e) => setParagraph(e.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Image
                            </Form.Label>
                            <Col sm={10}>
                                <div className="mb-3">
                                    <img
                                        src={previewUrl}
                                        alt="Preview"
                                        style={{ width: "100%", maxHeight: "200px", objectFit: "contain" }}
                                    />
                                </div>
                                <Form.Control
                                    type="file"
                                    onChange={handleImagePreviewChange}
                                />
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AdminBanners;
