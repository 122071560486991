import React from 'react';
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Contactus from "./Pages/Contactus"
import UsedCarWarrenty from './Pages/UsedCarWarrenty';
import VehicleInspections from './Pages/VehicleInspections';
import VehicleService from './Pages/VehicleService';
import RoadSide from './Pages/RoadSide';
import BatteryReplacement from './Pages/BatteryReplacement';
import WindScreenRepair from './Pages/WindScreenRepair';
import FAQPage from './Pages/FAQPage';
import Testimonial from './Pages/Testominals';
import Pricing from './Pages/Pricing';
import Quotes from './Pages/Quotes'
import { Blogs } from "./Pages/Blogs"
import { BlogsDetail } from "./Pages/BlogsDetail"
import { SignUp } from "./Pages/Auth/SignUp"
import { SignIn } from "./Pages/Auth/SignIn"
import { BookServices } from './Pages/BookServices';
import AddPost from './Pages/admin/AddPost';
import UsersData from './Pages/admin/UsersData';
import Dashboard from './Pages/admin/Dashboard';
import AppointmentsData from './Pages/admin/BuyAppointements/Appointements';
import AdminBanners from './Pages/admin/AdminBanners';
import ContactsTable from './Pages/admin/ContactsTable';
import AdminPricings from './Pages/admin/Pricings';
import Coupans from './Pages/admin/Coupans';
import VehiclesData from './Pages/admin/VehiclesData';
import CartData from './Pages/admin/CartData';
import OrderDetails3 from './Pages/DisplayOrder';
// import VehicleDetailsPage from './pages/admin/CompleteOrder';
import CompleteOrder from './Pages/admin/CompleteOrder';
import PendingOrders from './Pages/admin/PendingOrders';
import ProductsDetails from './Pages/ProductsDetails';
import AddImages from './Pages/admin/AddImages';
import MakeCart from './Pages/MakeCart';
import Checkout from './Pages/Checkout';
import AllProducts from './Pages/admin/AllProduct';
import ProductSingle from './Pages/ProductSingle';
import CommentsData from './Pages/admin/CommentsData';
import UploadAllProducts from './Pages/admin/UploadAllProducts';
import CancelledOrder from './Pages/CancelledOrder';
function App() {
    return (
        <Router>
            <ToastContainer />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/About-us" element={<AboutUs />} />
                <Route path='/Contact-us' element={<Contactus />} />
                <Route path='/Used-Car-Warrenty' element={<UsedCarWarrenty />} />
                <Route path='/Vehicle-inspections' element={<VehicleInspections />} />
                <Route path='/Vehicle-service' element={<VehicleService />} />
                <Route path="/Road-side" element={<RoadSide />} />
                <Route path='/Battery-Replacement' element={<BatteryReplacement />} />
                <Route path='/wind-screen-Repair' element={<WindScreenRepair />} />
                <Route path='/Faq-Page' element={<FAQPage />} />
                <Route path='/Testominals' element={<Testimonial />} />
                <Route path='/Pricing' element={<Pricing />} />
                <Route path='/Blogs' element={<Blogs />} />
                <Route path='/Blogs/:id' element={<BlogsDetail />} />
                <Route path='/Sign_In' element={<SignIn />} />
                <Route path='/Sign_Up' element={<SignUp />} />
                <Route path='/Book_Services' element={<BookServices />} />
                <Route path='/GetQuotes' element={<Quotes />} />
                <Route path='/admin/Dashboard' element={<Dashboard />} />
                <Route path='/admin/AddPost' element={<AddPost />} />
                <Route path='/admin/ManageUsers' element={<UsersData />} />
                <Route path='/admin/Appointments' element={<AppointmentsData />} />
                <Route path='/admin/Upload-banners' element={<AdminBanners />} />
                <Route path='/admin/Message-Request' element={<ContactsTable />} />
                <Route path='/admin/Add-Pricings' element={<AdminPricings />} />
                <Route path='/admin/Add-Images' element={<AddImages />} />
                <Route path='/admin/Create-Coupans' element={<Coupans />} />
                <Route path='/admin/Vehicles-Data' element={<VehiclesData />} />
                <Route path='/admin/All_Products' element={<AllProducts />} />
                <Route path='/admin/Upload_All_Products' element={<UploadAllProducts />} />
                <Route path='/admin/All_Comments' element={<CommentsData />} />

                <Route path='/admin/Cart-Data' element={<CartData />} />
                <Route path='/admin/Pending-Orders' element={<PendingOrders />} />
                <Route path='/admin/displayorder' element={<OrderDetails3 />} />
                <Route path="/completeorder/:id" element={<CompleteOrder />} />
                <Route path='/admin/Canceled-orders' element={<CancelledOrder />} />
                <Route path="/Product-details" element={<ProductsDetails />} />
                <Route path="/Product-single" element={<ProductSingle />} />
                <Route path="/Cart_Make" element={<MakeCart />} />
                <Route path="/Checkout" element={<Checkout />} />



            </Routes>
        </Router>
    );
}

export default App;
