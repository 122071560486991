import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import brand1 from "../../assets/images/carLogo 01.jpg";
import brand2 from "../../assets/images/carLogo 16_new.png";
import brand3 from "../../assets/images/carLogo 03_new.jpg";
import brand4 from "../../assets/images/carLogo 04.jpg";
import brand5 from "../../assets/images/carLogo 05_new.jpg";
import brand6 from "../../assets/images/carLogo 06_new.jpg";
import brand7 from "../../assets/images/carLogo 07.jpg";
import brand8 from "../../assets/images/carLogo 08.jpg";
import brand9 from "../../assets/images/carLogo 09.jpg";
import brand10 from "../../assets/images/carLogo 10.jpg";
import brand11 from "../../assets/images/carLogo 11_new.jpg";
// import brand12 from "../../assets/images/carLogo 12.jpg";
import brand13 from "../../assets/images/carLogo 13.jpg";
import brand14 from "../../assets/images/carLogo 14_new.jpg";
import brand15 from "../../assets/images/carLogo 15_new.png";
import brand16 from "../../assets/images/carLogo 16_new.png";
import brand17 from "../../assets/images/carLogo 17.jpg";
import brand18 from "../../assets/images/carLogo 18.png";
import brand19 from "../../assets/images/carLogo 19_new.png";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const BrandsPartners = () => {
  const [index, setIndex] = useState(0);

  const brands = [brand1, brand2, brand3, brand4, brand5, brand6, brand7, brand8, brand9, brand10, brand11, brand13, brand14, brand15, brand16, brand17, brand18, brand19];
  const totalSlides = Math.ceil(brands.length / 6);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex === totalSlides - 1 ? 0 : prevIndex + 1));
    }, 5000);
    return () => clearInterval(interval);
  }, [totalSlides]);

  const handlePrevSlide = () => {
    setIndex(index === 0 ? totalSlides - 1 : index - 1);
  };

  const handleNextSlide = () => {
    setIndex(index === totalSlides - 1 ? 0 : index + 1);
  };

  const renderBrandItems = () => {
    const brandItems = [];
    for (let i = 0; i < totalSlides; i++) {
      const startIdx = i * 6;
      const endIdx = startIdx + 6;
      brandItems.push(
        <Carousel.Item key={i}>
          <div className="d-flex justify-content-center">
            {brands.slice(startIdx, endIdx).map((brand, idx) => (
              <img key={idx} className="set_width_carousel" src={brand} alt={`Brand ${startIdx + idx + 1}`} />
            ))}
          </div>
        </Carousel.Item>
      );
    }
    return brandItems;
  };

  return (
    <div className="container">
      <div className="position-relative mt-5">
        <Carousel fade indicators={false} activeIndex={index} interval={null}>
          {renderBrandItems()}
        </Carousel>
        {/* <FaChevronLeft className="carousel-nav left" onClick={handlePrevSlide} />
        <FaChevronRight className="carousel-nav right" onClick={handleNextSlide} /> */}
      </div>
    </div>
  );
};

export default BrandsPartners;
