import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Footer from "../../Components/Footer";
import MainHeader from "../../Components/MainHeader";
import { db } from "../../firebase";

const MakeCart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCars } = location.state || {};

  const [cartItems, setCartItems] = useState(selectedCars || []);

  const updateQuantity = (id, quantity) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? {
              ...item,
              quantity: quantity,
              totalPrice: quantity * parseFloat(item.plan.price),
            }
          : item
      )
    );
  };

  const removeItem = (id) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const calculateTotals = () => {
    const totalPrice = cartItems.reduce((sum, item) => sum + item.totalPrice, 0);
    const discount = 50; // Example fixed discount, replace with actual logic
    const total = totalPrice - discount;
    return { totalPrice, discount, total };
  };

  const { totalPrice, discount, total } = calculateTotals();

  // Function to generate PDF and save invoice
  const generateInvoice = async () => {
    const doc = new jsPDF();
  
    // Set title
    doc.setFontSize(18);
    doc.text("Motor Easy Invoice", 14, 22);
  
    // Customer details
    doc.setFontSize(12);
    doc.text("Customer Name: John Doe", 14, 30);
    doc.text("Invoice Date: " + new Date().toLocaleDateString(), 14, 36);
  
    // Define table columns and rows
    const columns = ["Product", "Quantity", "Price per Unit", "Total"];
    const rows = cartItems.map((item) => [
      item.name,
      item.quantity,
      `$${parseFloat(item.plan.price).toFixed(2)}`,
      `$${item.totalPrice.toFixed(2)}`,
    ]);
  
    // Add table
    doc.autoTable({
      startY: 40,
      head: [columns],
      body: rows,
    });
  
    // Add totals
    const finalY = doc.lastAutoTable.finalY;
    doc.text(`Total Price: $${totalPrice.toFixed(2)}`, 14, finalY + 10);
    doc.text(`Discount: $${discount.toFixed(2)}`, 14, finalY + 16);
    doc.text(`Total: $${total.toFixed(2)}`, 14, finalY + 22);
  
    // Add additional notes
    doc.setFontSize(10);
    doc.text(
      "Thank you for choosing Motor Easy! We offer a wide range of services \n" +
      "to keep your car in top condition. Please refer to our service \n" +
      "policy for more details. All services are performed by certified \n" +
      "technicians and include a 30-day satisfaction guarantee.",
      14,
      finalY + 32
    );
  
    doc.text(
      "Payment Methods: \n" +
      "We accept credit card, debit card, and PayPal. All transactions are \n" +
      "secured and encrypted for your safety.",
      14,
      finalY + 52
    );
  
    // Add policy
    doc.setFontSize(12);
    doc.text(
      "Refund Policy: \n" +
      "You can return your purchase within 30 days for a full refund if \n" +
      "you're not satisfied. Ensure the product is in its original condition.",
      14,
      finalY + 72
    );
  
    // Save the PDF
    // doc.save("invoice.pdf");
  
    // Store invoice details to Firestore
    const invoiceData = {
      cartItems,
      totalPrice,
      discount,
      total,
      date: new Date().toLocaleDateString(),
    };
  
    try {
      await db.collection("InvoiceCartMake").add(invoiceData);
      console.log("Invoice data saved to Firestore successfully.");
    } catch (error) {
      console.error("Error saving invoice to Firestore:", error);
    }
  };
  

  // Handle purchase action
  const handlePurchase = async () => {
    // Generate the invoice
    await generateInvoice();

    // Navigate to the checkout page with data
    navigate("/checkout", {
      state: {
        cartItems,
        totalPrice,
        discount,
        total,
      },
    });
  };

  return (
    <>
      <MainHeader />
      <main className="p-5"  style={{translate: "0 -40px"}}>
        <section className="section-content padding-y">
          <div className="container">
            <div className="row">
              <main className="col-md-9">
                <div className="card">
                  <table className="table table-borderless table-shopping-cart">
                    <thead className="text-muted">
                      <tr className="small text-uppercase">
                        <th scope="col">Product</th>
                        <th scope="col" width={120}>
                          Quantity
                        </th>
                        <th scope="col" width={120}>
                          Price
                        </th>
                        <th scope="col" className="text-right" width={200}>
                          {" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <figure className="itemside">
                              <div className="aside d-flex   row align-items-start">
                                <div className="col-md-4 p-0 ">
                                  <img
                                    src={item.image}
                                    width={120}
                                    className="img-sm"
                                    alt={item.name}
                                  />
                                </div>
                                <div className="cart_details_name col-md-8 ">
                                  <a
                                    href="javascript:void(0)"
                                    className="title text-dark fw-bold text-decoration-none"
                                  >
                                    {item.name}
                                  </a>
                                    <div className="first">
                                      <p
                                        className="mb-0 text-muted fw-medium"
                                        style={{ fontSize: "12px" }}
                                      >
                                        car: {item.carName || "N/A"}
                                      </p>
                                      <p
                                        className="mb-0 text-muted fw-medium"
                                        style={{ fontSize: "12px" }}
                                      >
                                        engine: {item.engineCapacity || "N/A"}
                                      </p>
                                      <p
                                        className="mb-0 text-muted fw-medium"
                                        style={{ fontSize: "12px" }}
                                      >
                                        Services: {item.serviceType || "N/A"}
                                      </p>
                                       <p
                                        className="mb-0 text-muted fw-medium"
                                        style={{ fontSize: "12px" }}
                                      >
                                        Type: {item.carType || "N/A"}
                                      </p>
                                    </div>
                                  
                                </div>
                              </div>
                            </figure>
                          </td>
                          <td>
                            <select
                              className="form-control"
                              value={item.quantity}
                              onChange={(e) =>
                                updateQuantity(item.id, Number(e.target.value))
                              }
                            >
                              {[...Array(10).keys()].map((i) => (
                                <option key={i + 1} value={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <div className="price-wrap">
                              <var className="price">
                                ${item.totalPrice.toFixed(2)}
                              </var>
                              <small className="text-muted">
                                {" "}
                                ${parseFloat(item.plan.price).toFixed(2)} each{" "}
                              </small>
                            </div>
                          </td>
                          <td className="text-right">
                            <button
                              onClick={() => removeItem(item.id)}
                              className="btn btn-light btn-round"
                            >
                              {" "}
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="card-body border-top">
                    <button
                      onClick={handlePurchase} // Handle purchase and generate invoice
                      className="btn btn-primary float-md-right"
                    >
                      {" "}
                      Make Purchase <i className="fa fa-chevron-right" />{" "}
                    </button>
                    <button
                      onClick={() => navigate("/")} // Navigate back to product-details
                      className="btn btn-light"
                    >
                      {" "}
                      <i className="fa fa-chevron-left" /> Continue shopping{" "}
                    </button>
                  </div>
                </div>
                <div className="alert alert-success mt-3">
                  <p className="icontext">
                    <i className="icon text-success fa fa-truck" /> Delivery
                    within 1-2 weeks
                  </p>
                </div>
              </main>
              <aside className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <dl className="dlist-align">
                      <dt>Total price:</dt>
                      <dd className="text-right">${totalPrice.toFixed(2)}</dd>
                    </dl>
                    <dl className="dlist-align">
                      <dt>Discount:</dt>
                      <dd className="text-right">${discount.toFixed(2)}</dd>
                    </dl>
                    <dl className="dlist-align">
                      <dt>Total:</dt>
                      <dd className="text-right  h5">
                        <strong>${total.toFixed(2)}</strong>
                      </dd>
                    </dl>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </section>
        <section className="section-name bg padding-y">
          <div className="container">
            <h6>Payment and Refund Policy</h6>
            <p>
              We offer a straightforward refund policy. If you are not satisfied
              with your purchase, you can return it within 30 days for a full
              refund. Please ensure the product is in its original condition and
              packaging.
            </p>
            <p>
              Payment methods accepted include credit card, debit card, and
              PayPal. We ensure all transactions are secure and encrypted for
              your safety.
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default MakeCart;
