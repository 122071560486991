import React, { useState, useEffect, useContext } from "react";
import {
    Card,
    Button,
    Container,
    Row,
    Col,
    Table,
    Modal,
    Form,
    Dropdown,
    DropdownButton,
} from "react-bootstrap";
import { db } from "../../firebase";
import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import AdminHeader from "../../Components/AdminHeader";
import Sidebar from "../../Components/Sidebar";
import { StepContext } from "../../Context/StepContext"; // Import the context

export default function OrderDetails3() {
    const [orders, setOrders] = useState([]);
    const [currentOrder, setCurrentOrder] = useState(null);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const { addPendingOrder } = useContext(StepContext); // Use the context to get addPendingOrder

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "Orders"));
                const fetchedOrders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setOrders(fetchedOrders);
            } catch (error) {
                console.error("Error fetching orders: ", error);
            }
        };

        fetchOrders();
    }, []);

    const handleShowDetails = (order) => {
        setCurrentOrder(order);
        setShowDetailsModal(true);
    };

    const handleShowEdit = (order) => {
        setCurrentOrder(order);
        setShowEditModal(true);
    };

    const handleShowStatus = (order) => {
        setCurrentOrder(order);
        setShowStatusModal(true);
    };

    const handleDeleteOrder = async (orderId) => {
        try {
            await deleteDoc(doc(db, "Orders", orderId));
            setOrders(orders.filter(order => order.id !== orderId));
        } catch (error) {
            console.error("Error deleting order: ", error);
        }
    };

    const handleEditChange = (field, value) => {
        setCurrentOrder((prevOrder) => ({
            ...prevOrder,
            userDetails: {
                ...prevOrder.userDetails,
                [field]: value,
            },
        }));
    };

    const handleSaveChanges = async () => {
        try {
            const orderRef = doc(db, "Orders", currentOrder.id);
            await updateDoc(orderRef, currentOrder);
            setOrders(orders.map(o => (o.id === currentOrder.id ? currentOrder : o)));
            setShowEditModal(false);
        } catch (error) {
            console.error("Error updating order: ", error);
        }
    };

    const handleStatusChange = async (status) => {
        const updatedOrder = { ...currentOrder, status: status };

        try {
            const orderRef = doc(db, "Orders", updatedOrder.id);
            await updateDoc(orderRef, updatedOrder);
            setOrders(orders.map(o => (o.id === updatedOrder.id ? updatedOrder : o)));
            setShowStatusModal(false);

            const pendingOrderRef = doc(db, "PendingOrders", updatedOrder.id);

            if (status === "Pending") {
                // Add order to PendingOrders collection
                await updateDoc(pendingOrderRef, updatedOrder);
            } else {
                // Remove order from PendingOrders collection
                await deleteDoc(pendingOrderRef);
            }

        } catch (error) {
            console.error("Error updating status: ", error);
        }
    };


    return (
        <>
            <AdminHeader />

            <div className="container-fluid p-0">
                <div className="row">
                    <Sidebar />
                    <section style={{ backgroundColor: "#1bb7ff" }} className="col-md-9">
                        <Container className="py-5" style={{ maxWidth: "1500px" }}>
                            <Row className="justify-content-center align-items-center">
                                <Col md={12}>
                                    <Card style={{ borderRadius: "10px", overflowX: "auto" }}>
                                        <Card.Header className="px-3 py-3">
                                            <h4 className="text-muted mb-0">Order Details</h4>
                                        </Card.Header>
                                        <Card.Body className="p-4">
                                            <Table className="mb-4" striped bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Booking Date</th>
                                                        <th>Customer Name</th>
                                                        <th>Phone</th>
                                                        <th>Postcode</th>
                                                        <th>Car Number</th>
                                                        <th>Model</th>
                                                        <th>Reg Date</th>
                                                        <th>MOT Exp Date</th>
                                                        <th>Total Price</th>
                                                        <th>Discount</th>
                                                        <th>Status</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orders.length > 0 ? (
                                                        orders.map((order, index) => (
                                                            <tr key={index}>
                                                                <td>{order.date}</td>
                                                                <td>{order.userDetails.name}</td>
                                                                <td>{order.userDetails.phone || "N/A"}</td>
                                                                <td>{order.userDetails.zip}</td>
                                                                <td>{order.userDetails.carNumber || "N/A"}</td>
                                                                <td>{order.userDetails.model || "N/A"}</td>
                                                                <td>{order.userDetails.regDate || "N/A"}</td>
                                                                <td>{order.userDetails.motExpDate || "N/A"}</td>
                                                                <td>${order.totalPrice}</td>
                                                                <td>${order.discount}</td>
                                                                <td>{order.status || "Pending"}</td>
                                                                <td>
                                                                    <div
                                                                        style={{
                                                                            display: "grid",
                                                                            gridTemplateColumns: "1fr 1fr",
                                                                            gap: "10px",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            size="sm"
                                                                            variant="info"
                                                                            onClick={() => handleShowDetails(order)}
                                                                        >
                                                                            Details
                                                                        </Button>
                                                                        <Button
                                                                            size="sm"
                                                                            variant="warning"
                                                                            onClick={() => handleShowEdit(order)}
                                                                        >
                                                                            Edit
                                                                        </Button>
                                                                        <Button
                                                                            size="sm"
                                                                            variant="primary"
                                                                            onClick={() => handleShowStatus(order)}
                                                                        >
                                                                            Status
                                                                        </Button>
                                                                        <Button
                                                                            size="sm"
                                                                            variant="danger"
                                                                            onClick={() => handleDeleteOrder(order.id)}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="12">No orders found.</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                        <Card.Footer
                                            className="border-0 px-2 py-3"
                                            style={{
                                                backgroundColor: "#1bb7ff",
                                                borderBottomLeftRadius: "10px",
                                                borderBottomRightRadius: "10px",
                                            }}
                                        >
                                            <h5 className="d-flex align-items-center justify-content-end text-white text-uppercase mb-0">
                                                Thank you for shopping with us!
                                            </h5>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </div>

            {/* Order Details Modal */}
            {currentOrder && (
                <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Order Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Customer Information</h5>
                        <p><strong>Name:</strong> {currentOrder.userDetails.name}</p>
                        <p><strong>Email:</strong> {currentOrder.userDetails.email}</p>
                        <p><strong>Phone:</strong> {currentOrder.userDetails.phone}</p>
                        <p><strong>Address:</strong> {currentOrder.userDetails.address}, {currentOrder.userDetails.state}, {currentOrder.userDetails.country}</p>
                        <p><strong>Postcode:</strong> {currentOrder.userDetails.zip}</p>

                        <h5>Car Information</h5>
                        <p><strong>Car Number:</strong> {currentOrder.userDetails.carNumber}</p>
                        <p><strong>Model:</strong> {currentOrder.userDetails.model}</p>
                        <p><strong>Reg Date:</strong> {currentOrder.userDetails.regDate}</p>
                        <p><strong>MOT Exp Date:</strong> {currentOrder.userDetails.motExpDate}</p>

                        <h5>Order Items</h5>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentOrder.cartItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>{item.plan.price}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.totalPrice}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Edit Order Modal */}
            {currentOrder && (
                <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formCustomerName" className="mb-3">
                                <Form.Label>Customer Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentOrder.userDetails.name}
                                    onChange={(e) => handleEditChange("name", e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formPhone" className="mb-3">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentOrder.userDetails.phone}
                                    onChange={(e) => handleEditChange("phone", e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formZip" className="mb-3">
                                <Form.Label>Postcode</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentOrder.userDetails.zip}
                                    onChange={(e) => handleEditChange("zip", e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formCarNumber" className="mb-3">
                                <Form.Label>Car Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentOrder.userDetails.carNumber}
                                    onChange={(e) => handleEditChange("carNumber", e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formModel" className="mb-3">
                                <Form.Label>Model</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentOrder.userDetails.model}
                                    onChange={(e) => handleEditChange("model", e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formRegDate" className="mb-3">
                                <Form.Label>Reg Date</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentOrder.userDetails.regDate}
                                    onChange={(e) => handleEditChange("regDate", e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formMotExpDate" className="mb-3">
                                <Form.Label>MOT Exp Date</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentOrder.userDetails.motExpDate}
                                    onChange={(e) => handleEditChange("motExpDate", e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSaveChanges}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Status Modal */}
            {currentOrder && (
                <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Order Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DropdownButton
                            id="dropdown-status"
                            title={currentOrder.status || "Pending"}
                            onSelect={handleStatusChange}
                        >
                            <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                            <Dropdown.Item eventKey="Delivered">Delivered</Dropdown.Item>
                            <Dropdown.Item eventKey="Cancelled">Cancelled</Dropdown.Item>
                        </DropdownButton>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowStatusModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

        </>
    );
}
